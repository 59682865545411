<template>
  <section class="statistics-total-wrap">
    <div class="top-wrap">
      <span class="title">舆情主图态势图</span>
      <el-dropdown trigger="click" @command="refresh" style="float: right;cursor: pointer;">
            <span class="el-dropdown-link">
              {{range}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="当天">当天</el-dropdown-item>
          <el-dropdown-item command="三天内">三天内</el-dropdown-item>
          <el-dropdown-item command="七天内">七天内</el-dropdown-item>
          <el-dropdown-item command="一个月内">一个月内</el-dropdown-item>
          <el-dropdown-item command="自定义">自定义</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-date-picker
        v-if="this.range === '自定义'"
        @change="timeModify"
        style="float: right;margin-right: 10px;margin-top: -5px"
        size="small"
        value-format="yyyy-MM-dd HH:mm:ss"
        v-model="timeRange"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期">
      </el-date-picker>
    </div>
    <section class="content-wrapper">
      <section class="tree-content">
        <span class="title">选择监测主题分类</span>
        <el-tree :data="data" @check-change="modifyIds" show-checkbox node-key="id" ref="tree"
        :expand-on-click-node="false" :check-strictly="true">
          <span class="custom-tree-node" slot-scope="{node, data}">
            <span>{{ data.title }}</span>
          </span>
        </el-tree>
        <div class="my-button">
          <el-button @click="toggle" type="primary" size="medium">切换</el-button>
        </div>
      </section>
      <section class="chart-content">
        <div class="noData" v-show="noData">暂无数据</div>
        <div id="situationMap"></div>
      </section>
    </section>
  </section>
</template>

<script>
import { selectAllType } from '@/api/dataOutput'
import { totalStat } from '@/api/total'
export default {
  data () {
    return {
      tu: '',
      range: '一个月内',
      timeRange: '',
      startDate: '',
      endDate: '',
      noData: false,
      msids: [],
      data: [{
        id: '123fa',
        label: '万科新闻',
        children: [{
          id: '12va1',
          label: '万科新闻'
        }]
      },
      {
        id: '123aw',
        label: '万科高管',
        children: [{
          id: '12da2',
          label: '陈建忠'
        }, {
          id: '12sw1',
          label: '丁宁'
        }]
      },
      {
        id: '1xaw1',
        label: '万科宁波住宅',
        children: [{
          id: '12saw',
          label: '宁波白石湖东'
        }, {
          id: '12awq',
          label: '宁波滨河道'
        }]
      }]
    }
  },
  mounted () {
    this.tu = this.$echarts.init(document.getElementById('situationMap'))
    this.allMS()
    this.queryData('4')
  },
  methods: {
    timeModify () {
      console.log(this.timeRange)
      this.startDate = this.timeRange[0]
      this.endDate = this.timeRange[1]
      this.queryData('5')
    },
    refresh (command) {
      this.range = command
      if (command === '三天内') {
        this.queryData('2')
      } else if (command === '七天内') {
        this.queryData('3')
      } else if (command === '一个月内') {
        this.queryData('4')
      } else if (command === '当天') {
        this.queryData('1')
      }
    },
    async queryData (type) {
      this.tu.showLoading({
        text: '加载中...',
        color: '#c23531',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0
      })
      const res = await totalStat({ type: type, msIds: this.msids, startDate: this.startDate, endDate: this.endDate })
      if (res.data.series.length === 0) {
        this.noData = true
      } else {
        this.noData = false
      }
      this.situationCharts(res)
      this.tu.hideLoading()
    },
    async allMS () {
      const res = await selectAllType()
      this.data = res.data
    },
    modifyIds (res) {
    },
    toggle () {
      // console.log(this.$refs.treeCon.getCheckedNodes())
      var trees = this.$refs.tree.getCheckedNodes()
      var ids = []
      for (var i = 0; i < trees.length; i++) {
        ids.push(trees[i].id)
      }
      console.log(ids)
      this.msids = ids
      this.refresh(this.range)
    },
    situationCharts (res) {
      res.data.series.forEach((item,index)=>{
        item.symbol ='none'
      })
      this.tu.setOption({})
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          data: res.data.legend
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: res.data.xAxis
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: res.data.series
      }

      this.tu.setOption(option, true)
    }
  }
}
</script>

<style lang="scss">
.statistics-total-wrap {
  width: calc(100vw - 330px);
  height: calc(100vh - 160px);
  background-color: #fff;
  box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.1);
  border-radius: 2px;
  .top-wrap{
    border-bottom: 1px solid #eaeaea;
    padding: 15px 20px;
    .title{
      font-size: 17px;
    }
  }
  .content-wrapper{
    display: flex;
    justify-content: space-between;
    height: calc(100% - 50px);
  }
  .tree-content {
    width: 277px;
    height: 100%;
    padding: 20px;
    position: relative;
    padding-right: 0;
    border-right: 3px solid #eaeaea;
    .title {
      font-size: 16px;
      color: #606266;
    }
    .el-tree {
      width: 100%;
      margin-top: 20px;
      height: calc(100% - 70px);
      overflow-y: scroll;
    }
    .el-tree::-webkit-scrollbar {
     width: 8px;
    }
    .el-tree::-webkit-scrollbar-track {
     background-color:#f8f8f8;
     -webkit-border-radius: 2em;
     -moz-border-radius: 2em;
     border-radius:2em;
    }
    .el-tree::-webkit-scrollbar-thumb {
     background-color:#dddddd;
     -webkit-border-radius: 2em;
     -moz-border-radius: 2em;
     border-radius:2em;
    }
    .my-button {
      margin-top: 50px;
      text-align: center;
      position: absolute;
      bottom: 10px;
      left: 80px;
    }
  }
  .chart-content {
    // width: 940px;
    width: calc(100% - 227px);
    height: 100%;
    // background: rgba(255,255,255,1);
  /*  box-shadow: 2px 2px 10px 0px rgba(207,207,207,0.5);
    border-radius: 10px; */
    padding: 20px;
    position: relative;
    .noData{
      width: 100%;
      text-align: center;
      position: absolute;
      color: #898989;
      top: 200px;
    }
    .title {
      font-size: 16px;
      font-weight:500;
      color: #666666;
      line-height:24px;
    }
    #situationMap {
      width: 100%;
      height: calc(100% - 100px);
    }
  }
}
</style>
